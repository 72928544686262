<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo" :to="{ path:'/' }">
      <logo />
      <h2 class="brand-text text-primary ml-1">
        NYSSMA
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          You are not authorized! 🔐
        </h2>
        <p class="mb-2">
          You don’t have permission to access this page. Go Home!!
        </p>
        <b-button variant="primary" class="mb-1 btn-sm-block" :to="{path:'/'}">
          Back to Home
        </b-button>
				<b-button variant="outline-primary" class="mb-1 ml-1 btn-sm-block" @click="logout">
					Sign Out
				</b-button>
        <b-img
          fluid
          :src="imgUrl"
          alt="Not authorized page"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton } from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import store from '@/store'
import {Auth} from 'aws-amplify';

export default {
  components: {
    BLink, BImg, BButton, Logo,
  },
  data() {
    return {
      downImg: require('@/assets/images/illustration/security.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
      async logout() {
          try {
              await Auth.signOut().then(async () => {
                  await sessionStorage.clear()
                  await store.dispatch('all-state-applications/clear')
                  await store.dispatch('all-state-scores/clear')
                  await store.dispatch('account/logout').then(() => {
                      this.$router.push({name: 'auth-login'})
                  })
              })
          }
          catch (error) {
              console.error(error)
          }
      },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
